 :root {
   --swiper-navigation-size: 44px;
   --swiper-navigation-bgcolor: red !important;

   --swiper-navigation-top-offset: 50%;
   --swiper-navigation-sides-offset: 10px;
   --swiper-navigation-bg-color: rgba(0, 0, 0);
 }
 .rounded-10{border-radius: 10px;}
 .btn-primary.text-primary:hover,
 .btn-primary.text-primary:focus {
   color: #ffffff !important;
   background-color: var(--dark-blue) !important;
 }

 .text-yellow {
   color: #FFB800;
 }

 .action-option li.active img.default {
   display: none;
 }

 .action-option li.active img.like {
   display: inline-block !important;
 }

 app-validation-error {
   font-size: 13px;
 }

 .width-24 {
   width: 24px;
 }

 .minh-44 {
   min-height: 44px;
 }

 //  .card-title_mh {
 //    min-height: 74px;
 //  }
 .card-banner img {
   object-position: top;
 }

 .card-title_mh {
   min-height: 10px !important;
   max-height: 86px !important;
 }

 .custom-height-50 {
   min-height: 50px;
 }

 .dropdown-toggle::after {
   transition: all 0.5s ease;
   -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -ms-transition: all 0.5s ease;
 }

 .minh-150 {
   min-height: 150px;
 }

 .minh-260 {
   min-height: 260px;
 }

 .minh-280 {
   min-height: 280px;
 }

 .pe-90 {
   padding-right: 90px;
 }

 .width-15 {
   width: 15px;
 }
 .width-18 {
  width: 18px;
}
 .width-20 {
   width: 20px;
 }

 .width-26 {
   width: 26px;
 }

 .width-30 {
   width: 30px;
 }

 .mxw-75 {
   max-width: fit-content;
 }

 .top-4 {
   top: 4px
 }

 .lh-14 {
   line-height: 14px;
 }

 .vertical-align-top {
   vertical-align: top;
 }

 .z-99999 {
   z-index: -99999 !important;
 }

 .modal-dialog.modal-lg.width-750 {
   width: 750px !important;
 }

 .transition-all {
   transition: all 0.5s ease;
   -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -ms-transition: all 0.5s ease;
 }

 .translate-10:hover {
   top: -8px !important;
 }

 .fill-white {
   fill: #ffffff;
 }

 .no-dropdown-before::after {
   display: none;
 }

 .top-1 {
   top: -1px;
 }

 .top-m2 {
   top: -2px;
 }

 .top-n2 {
   top: -2px;
 }

 .right-15 {
   right: 15px;
 }

 .top-15 {
   top: 15px;
 }

 .bottom-15 {
   bottom: 15px;
 }

 .bottom-10 {
   bottom: 10px;
 }

 .left-15 {
   left: 15px;
 }

 .mxw-1024 {
   max-width: 1024px;
 }

 .opacity-07 {
   opacity: 0.7;
 }

 .back-arrow.opacity-07:hover {
   opacity: 1;
 }

 /* You can add global styles to this file, and also import other style files */
 .col-4.welcome__col {
   width: 35%;
 }

 .col-3.welcome__col {
   width: 26%;
 }

 .col-3.welcome__col img,
 .col-4.welcome__col img {
   width: 100%;
 }

 a.lb-close {
   position: absolute !important;
   top: 0;
   margin-left: 20px;
   background-color: #ab0909 !important;
   opacity: 1 !important;
   width: 40px !important;
   height: 40px !important;
   background-size: 17px !important;
   border-radius: 100%;
   background-position: 12px 11px !important;
 }

 // swiper

 swiper-container {
   width: 100%;
   height: 100%;
 }

 swiper-slide {
   text-align: center;
   font-size: 18px;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 //  swiper-slide img {
 //    display: block;
 //    width: 100%;
 //    height: 100%;
 //    object-fit: cover;
 //  }

 //  swiper-slide {
 //    width: 75%;
 //  }

 .swiper-horizontal {
   touch-action: pan-y;
   padding-bottom: 80px;
 }

 .swiper-pagination {
   display: none !important;
 }

 .swiper-button-next,
 .swiper-button-prev {
   bottom: 10px;
   top: inherit !important;
 }

 .swiper-button-next {
   top: inherit !important;
   bottom: 0 !important;
   display: block !important;
 }

 div#scrollNews app-news-card-ui .card {
  min-height: 470px;
}

 @media (min-width: 1200px) {
   // .slider-wrap {
   //   // width: calc(50% + 555px);
   //   // margin-left: auto;
   // }
 }

 // swiper end
 .more-country {
  border: 1px solid #e3e3e3;
  border-radius: 44px;
  padding: 4px 11px;
  background: #f7f7f7;
  font-weight: 400;
}
.more-country strong {
  color: #0072ce;
  font-size: 15px;
  font-weight: 700;
}
.more-country:hover{background: var(--bs-white);}
.country-radius-4{border-radius: 4px !important;}


 @media (min-width: 576px) {
   .modal-dialog {
     width: 520px !important;
   }

   .signup-modal {
     width: 520px !important;
   }

   .modal-dialog.modal-lg {
     width: 580px !important;
   }

   .modal-dialog.modal-xl {
    width: 780px !important;
  }
 }

 .or span {
   position: absolute;
   left: 0;
   right: 0;
   margin: 0 auto;
   background: white;
   width: 50px;
   height: 30px;
   border-radius: 100%;
   text-align: center;
   top: -15px;
   color: var(--grey);
 }

 .google-btn:hover span {
   color: #ffffff;
 }

 strong.close {
   position: absolute;
   top: -3px;
   right: -3px;
   cursor: pointer;
   border-radius: 100%;
   display: none;
 }

 strong.close i.fas.fa-window-close {
   color: var(--bs-danger);
 }

 .badge:hover strong.close {
   display: block;
   cursor: pointer;
 }

 .tags span:hover .close {
   display: block;
 }

 .or span {
   position: absolute;
   left: 0;
   right: 0;
   margin: 0 auto;
   background: white;
   width: 50px;
   height: 30px;
   border-radius: 100%;
   text-align: center;
   top: -15px;
   color: var(--grey);
 }

 .google-btn:hover span {
   color: #ffffff;
 }

 asl-google-signin-button iframe {
   margin-left: auto !important;
   margin-right: auto !important;
   min-width: 252px !important;
 }
 .g-btn{
  background-color: #fff;
  color: #505050 !important;
  border: 1px solid #eaeaea;
  max-width: 203px;
  font-size: 13px;
  position: relative;
  left: -3px;
}
.g-btn span,.g-btn:hover span{color: #505050 !important;}

.g-btn svg {
  max-width: 20px;
}

.g-btn span{
  position: relative;
  top: 2px;
}
 // .dropdown-menu:before {
 //   position: absolute;
 //   top: -14px;
 //   background: url(assets/images/arrow-point-to-down.svg) no-repeat;
 //   content: '';
 //   height: 14px;
 //   width: 29px;
 //   right: 0px;
 //   background-size: 100%;
 // }
 .dropdown-item {
   font-size: 0.9rem;
   color: var(--grey2);
   border-bottom: 1px solid #f5f4f4;
   font-weight: 600;
   padding-top: 10px;
   padding-bottom: 10px;
 }

 .dropdown-item:focus,
 .dropdown-item:hover {
   color: #ffffff;
   background-color: var(--primary);
 }

 .dropdown-menu {
   padding: 0;
   border: 1px solid #f3f2f2;
 }

 .dropdown-item.active,
 .dropdown-item:active {
   background-color: var(--primary);
 }

 .dropdown-with-icon .dropdown-item:focus path,
 .dropdown-with-icon .dropdown-item:hover path {
   fill: #ffffff !important;
   stroke: white !important;
 }

 .minh-100-custom {
   //  min-height: calc(100vh - 455px);
   min-height: calc(100vh - 380px);
 }

 @media(max-width:991px) {
  .fixed-tabs .col-12.col-md-6.d-flex.align-items-center ul.nav.nav-pills.evnt-list {
    position: absolute;
    left: 12px;
    margin-top: 80px;
  }
  .fixed-tabs .col-12.col-md-6.d-flex.align-items-center {
    min-height: 40px;
    padding-bottom: 70px;
    margin-top: 0px;   
    position: relative;
  }
   .minh-100-custom {
     min-height: calc(100vh - 705px);
   }
 }

 angular-editor.outline-red {
   .angular-editor-textarea {
     border: solid 1px red !important;
   }
 }

 app-multi-select-dropdown {
   .drop-toggle {
     border: solid 1px red;
   }
 }

 .action-option {
   background: var(--grey3);
 }

 .dislike svg {
   transform: rotate(-181deg);
   -webkit-transform: rotate(-181deg);
   -moz-transform: rotate(-181deg);
   -ms-transform: rotate(-181deg);
 }

 .dislike svg,
 .like svg {
   opacity: 0.6;
   cursor: pointer;
 }

 .dislike.active svg,
 .like.active svg {
   fill: var(--primary);
   opacity: 1;
 }

 .close-box svg {
   width: 25px;
   height: 25px;
   opacity: 0.7;
 }

 .more {
   cursor: pointer;
   z-index: 1;
 }

 .more-option .dropdown-toggle::after {
   display: none;
 }

 .more-option button {
   width: 30px;
   height: 30px;
   min-width: 30px;
   min-height: 30px; 
   border-radius: 100px !important;
   line-height: 20px;
 }


 .badge.post-status {
  position: absolute;
  right: 15px;
  top: 15px;
}
 

 // activity tabs pages

 .tags span {
   padding: 4px 10px;
   display: inline-block;
   background: var(--grey3);
   font-weight: 600;
   border-radius: 6px;
   font-size: 0.8rem;
 }

 .user-info_authername img {
   width: 26px;
   height: 26px;
   object-fit: cover;
   border: 1px solid var(--grey3);
 }

 .card-banner img {
   // aspect-ratio: 1.78/1.58;
   width: 100%;
   // object-fit: contain;
   // object-position: top;
 }

 .user-activity ul.nav.nav-pills li a {
   padding: 10px 40px;
   background: white;
   border-radius: 4px;
   color: var(--grey2);
   text-decoration: none;
 }

 .user-activity ul.nav.nav-pills li.active a,
 .user-activity ul.nav.nav-pills li a:hover {
   background: var(--primary);
   color: var(--bs-white);
 }

 // activity tabs pages end
 .btn-close {
   position: absolute;
   top: 10px;
   right: 10px;
   background-size: 13px;
   outline: none !important;
   z-index: 1;
 }


 .slick-slider {
   width: 100%;
   margin: auto;
 }

 body .slick-prev,
 body .slick-next {
   height: 45px;
   width: 45px;
   background: var(--primary) !important;
   z-index: 100;
   border-radius: 3px;
 }

 button.slick-prev.slick-arrow:before {
   content: '';
   background: url(/assets/images/back.png) no-repeat;
   background-size: 40px;
   width: 42px;
   height: 42px;
   display: none;
 }

 button.slick-next.slick-arrow:before {
   content: '';
   background: url(/assets/images/next.png) no-repeat;
   background-size: 40px;
   width: 42px;
   height: 42px;
   display: none;
 }




 body .slick-prev {
   margin-left: 0;
 }

 body .slick-next {
   margin-left: 0;
 }

 .slick-dots li button:before {
   font-size: 13px !important;
 }

 .wrap.slider-view .slick-list {
   border-radius: 15px;
 }

 .slick-dots li.slick-active button:before {
   color: var(--primary) !important;
 }

 .slick-dots {
   bottom: -45px !important;
 }

 span.add-img {
   bottom: 17px;
   right: 20px;
   background: white;
   width: 40px;
   height: 40px;
   text-align: center;
   border: 1px solid #e9e9e9;
 }

 .tooltip-body li {
   list-style: none;
 }

 .action-option li {
   list-style: none;
 }

 .stats-col p {
   line-height: 14px;
 }

 .border-grey {
   border: 1px solid #e5e5e5;
 }

 .thumb-icon:hover .close {
   display: block;
 }

 .slider-view .slick-prev:before,
 .slider-view .slick-next:before {
   color: var(--body-text);
 }

 .slider-view .slick-slider button {
   background: none !important;
   z-index: 1;
 }

 p.address span {
   display: inline-block;
 }

 // .title-img{background-color: var(--grey3);}
 .modal-dialog button.btn-close {
   background-color: #e3dede;
   border-radius: 100%;
   background-size: 9px;
   padding: 5px 5px !important;
   position: absolute;
   right: 16px;
   top: 15px;
 }


 .before-none:before {
   display: none;
 }


 .thumbnails .slick-list {
   padding: 10px 15px;

   strong.close {
     top: -12px;
     right: -5px;
   }

   .slick-track {
     display: flex;
     justify-content: center;
   }

 }


 asl-google-signin-button {
   max-width: 222px !important;
   min-width: 222px !important;
   margin: 0 auto;
   display: block;
   position: absolute;
    top: 0;
    left: 0;
    right: 0;
     opacity: 0;
 }
 
 .user-activity ul.nav.nav-pills li a {
   display: block;
 }

 .nothing-found {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-flow: column;
   background: #fff;
   padding: 40px 10px;
   border-radius: 5px;

   h3 {
     font-size: 20px;
     color: #777;
     margin-top: 29px;
   }

   .img-fluid {
     margin-left: 10px;
     max-height: 85px;
   }
 }

 .slick-slide {
   cursor: pointer;
   height: 0 !important;
 }
  .slick-active{
   height: fit-content !important;
 }


 .pagetitle-banner {
   background: url(/assets/images/toolbox/toolbox-bg.jpg) no-repeat;
   background-size: cover;

   h2 {
     padding: 20px 0;
     color: var(--bs-white);
     margin: 0;
   }
 }


 .user-placeholder {
   display: inline-block;
   width: 26px;
   min-height: 26px;
   min-width: 26px;
   height: 26px;
   object-fit: cover;
   border-radius: 100%;
   line-height: 26px;
   color: white;
   font-size: 0.7rem;
   text-align: center;
   margin-right: 5px;
   padding-left: 3px;
   overflow: hidden;
 }

 // badges
 .badge-primary {
   color: #fff;
   background-color: var(--primary);
 }

 .badge-secondary {
   color: #fff;
   background-color: #6c757d;
 }

 .badge-success {
   color: #fff;
   background-color: #2EAF2C;
 }

 .badge-danger {
   color: #fff;
   background-color: #dc3545;
 }

 .badge-warning {
   color: #212529;
   background-color: #EEAF34;
 }

 .badge-info {
   color: #fff;
   background-color: #17a2b8;
 }

 .badge-light {
   color: #212529;
   background-color: #f8f9fa;
 }

 .badge-dark {
   color: #fff;
   background-color: #343a40;
 }

 .swiper-button-next:after,
 .swiper-button-prev:after {
   font-size: 15px !important;
   background: rgb(255 255 255 / 31%) !important;
   width: 30px !important;
   height: 27px !important;
   text-align: center !important;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 100%;
   color: white !important;
 }

 .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
   display: none !important;
   opacity: 0 !important;
 }

 .no-pagination .swiper-horizontal {
   padding-bottom: 0 !important;
 }

 .swiper-button-next:after,
 .swiper-button-prev:after {
   font-size: 22px !important;
   color: #484141 !important;
   width: 40px !important;
   background: rgb(255 255 255 / 58%) !important;
   height: 40px !important;
   border-radius: 100% !important;
   text-align: center !important;
   display: flex !important;
   align-items: center !important;
   justify-content: center !important;
 }

 .swiper-button-next,
 :host(.swiper-rtl) .swiper-button-prev,
 .swiper-button-prev,
 :host(.swiper-rtl) .swiper-button-next {
   width: 40px !important;
   height: 40px !important;
   z-index: 1;
 }

 swiper-slide h5 {
   transition: all 0.5s ease;
   -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -ms-transition: all 0.5s ease;
 }

 swiper-slide:hover h5 {
   bottom: 10% !important;
 }

 .fixed-tabs {
   border-bottom: 1px solid #efecec;
 }

 .fixed-tabs ul.nav.nav-pills {
   margin-bottom: 0px !important;
 }

 .fixed-tabs ul.nav.nav-pills li {
   margin-top: 5px !important;
   margin-bottom: 5px !important;
 }

 .fixed-tabs ul.nav.nav-pills li a {
   padding: 5px 10px;
   background: #fdfdfd;
   border-radius: 5px;
   border: 1px solid #e5e5e5;
   color: #7e7979;
   font-size: 0.9rem;
 }

 .fixed-tabs ul.nav.nav-pills li.active a {
   background: #221d1d;
   color: #ffffff;
   border: 1px solid #221d1d;
 }



 .sorting-drop .btn-outline-secondary {
   background-color: transparent;
   border: 1px solid #dddddd;
 }

 .sorting-drop .btn-outline-secondary:hover,
 .sorting-drop .btn-outline-secondary:focus {
   background-color: transparent !important;
   border: 1px solid #dddddd !important;
   color: var(--body-text) !important;
 }

 .sorting-drop .dropdown-menu li a:hover,
 .sorting-drop .dropdown-menu li a:focus {
   color: var(--primary);
 }

 .dropdown.sorting-drop li.active .fa-check {
   position: absolute;
   right: 15px;
   display: block !important;
   margin-top: 3px;
   color: #fffffff7;
   background: #5b9f04f7;
   border-radius: 100%;
   padding: 4px 4px;
   height: 18px;
   width: 18px;
   font-size: 0.75rem;
 }

 .sorting-drop .btn-outline-secondary.show {
   background-color: var(--grey3) !important;
 }

 .sorting-drop .btn-outline-secondary.show::after {
   color: var(--body-text);
 }

 .user-info_authername {
   display: flex;
   align-items: center;
 }

 @media(max-width:1199px) {
   .sorting-drop .btn-outline-secondary {
     min-width: auto;
   }

   .sorting-drop span.filter-icon.position-absolute {
     position: relative !important;
   }

   .sorting-drop span.text-label {
     display: none;
   }

   .sorting-drop .dropdown-toggle::after {
     display: none;
   }
 }

 @media(max-width:767px) {
  .stats-col p { 
    font-size: 11px;
}
.contact_listing{word-wrap: break-word;}
  app-manage-activity ul.nav.nav-pills li {
    width: calc(50% - 8px); text-align: center;
}
   .custom-height-50 {
     min-height: 85px;
   }

   .fixed-tabs .container>.row>.col-12:first-child {
     order: 2;
   }

   .fixed-tabs .container>.row>.col-12:last-child {
     order: 1;
     margin-bottom: 10px;
   }

   .btn,
   .btn.fs-18 {
     padding: 6px 14px;
     font-size: 0.8rem;
   }

   button.slick-prev.slick-arrow:before {
     background-size: 30px;
     width: 32px;
     height: 32px;
   }

   button.slick-next.slick-arrow:before {
     background-size: 30px;
     width: 30px;
     height: 32px;
   }

   body .slick-prev {
     margin-left: 10px !important;
   }
   .fixed-tabs .col-12.col-md-6.d-flex.align-items-center ul.nav.nav-pills.evnt-list {
    position: inherit;
    left: inherit;
    margin-top: inherit;
  }
  .fixed-tabs .col-12.col-md-6.d-flex.align-items-center {
    min-height: 10px;
    padding-bottom: 0; 
  }
 }

 @media(max-width:576px) {
   .title h3 {
    //  font-size: 1.6rem;
     font-size: 18px;
   }
 }
 @media(max-width:414px) {
  .btn { 
    min-width: 100px; 
}
 .fixed-tabs ul.nav.nav-pills li a {
  padding: 5px 8px; 
}
 }

 .card-banner {
   border-bottom: 1px solid #efefef;
 }

 .wrap.slider-view .slick-list {
   padding: 0px !important;
 }

 app-project-detail .fixed-tabs ul.nav.nav-pills li a,
 app-explore-contribution-detail .fixed-tabs ul.nav.nav-pills li a,
 app-explore-detail .fixed-tabs ul.nav.nav-pills li a {
   padding: 10px 20px;
   color: var(--body-text);
   font-size: 0.9rem;
   font-weight: 700;
 }

 app-project-detail .fixed-tabs,
 app-explore-contribution-detail .fixed-tabs,
 app-explore-detail .fixed-tabs {
   border: none;
 }

 .profileImage {
   font-family: Arial, Helvetica, sans-serif;
   width: fit-content;
   padding: 5px;
   border-radius: 50%;
   color: #fff;
   text-align: center;
   margin: 2rem 0;
 }

 .description+span {
   border-left: 1px dashed #FFC107;
 }

 .my_animation {
   color: #EEE;
   text-align: center;
   animation: bounce .8s ease infinite alternate;
   -moz-animation: bounce .8s ease infinite alternate;
   -webkit-animation: bounce .8s ease infinite alternate;
   -ms-animation: bounce .8s ease infinite alternate;
 }


 @keyframes bounce {
   0% {
     text-shadow:
       0 5px 0 #ccc,
       0 2px 3px rgba(0, 0, 0, 1);
   }

   100% {
     transform: translateY(-5px);
     text-shadow: 0 50px 0 #000000,
       0 0px 20px rgba(0, 0, 0, .8);
   }
 }

 @-webkit-keyframes bounce {
   0% {
     text-shadow:
       0 5px 0 #ccc,
       0 2px 3px rgba(0, 0, 0, 1);
   }

   100% {
     transform: translateY(-5px);
     text-shadow: 0 50px 0 #000000,
       0 0px 20px rgba(0, 0, 0, .8);
   }
 }

 @-moz-keyframes bounce {
   0% {
     text-shadow:
       0 5px 0 #ccc,
       0 2px 3px rgba(0, 0, 0, 1);
   }

   100% {
     transform: translateY(-5px);
     text-shadow: 0 50px 0 #000000,
       0 0px 20px rgba(0, 0, 0, .8);
   }
 }

 @-ms-keyframes bounce {
   0% {
     text-shadow:
       0 5px 0 #ccc,
       0 2px 3px rgba(0, 0, 0, 1);
   }

   100% {
     transform: translateY(-5px);
     text-shadow: 0 50px 0 #000000,
       0 0px 20px rgba(0, 0, 0, .8);
   }
 }

 .my_animation2 {
   color: #EEE;
   text-align: center;
   animation: bounce2 .8s ease infinite alternate;
   -moz-animation: bounce2 .8s ease infinite alternate;
   -webkit-animation: bounce2 .8s ease infinite alternate;
   -ms-animation: bounce2 .8s ease infinite alternate;
 }


 @keyframes bounce2 {
   0% {}

   100% {
     transform: translateY(-5px);

   }
 }

 @-webkit-keyframes bounce2 {


   100% {
     transform: translateY(-5px);

   }
 }

 @-moz-keyframes bounce2 {


   100% {
     transform: translateY(-5px);
   }
 }

 @-ms-keyframes bounce2 {


   100% {
     transform: translateY(-5px);

   }
 }

 .dropdown.sorting-drop button {
   background-color: #ffffff !important;
   background: #ffffff !important;
 }

 .news .translate-10:hover {
   top: 0px !important;
 }
 .social-media {
  padding: 20px 20px;
  background: #f7f6f6;
  margin-bottom: 20px;
}
//  home hero css

 section.welcome ul.slick-dots {
   display: none !important;
 }

 section.welcome .slick-dotted.slick-slider {
   margin-bottom: 0;
 }

 section.welcome .slick-list {
   border-radius: 0px !important;
 }

 section.welcome .slick-prev {
   left: 10px;
 }

 section.welcome .slick-next {
   right: 10px;
 }

 //  home hero css end

 // home page news
 .news app-news-card-ui .card {
   min-height: 500px !important;
 }

 app-landing-page-card-ui .card:hover {
   top: 0 !important;
 }

 .swiper-pagination {
   display: none !important;
 }

 // home page news end
// home page news
.news app-news-card-ui .card {
  min-height: 530px !important;
}
app-landing-page-card-ui .card:hover{top: 0 !important;}
 
 .swiper-pagination{display: none !important;}
// home page news end


 .cursor-none {
   cursor: none;
 }

 .pointer-event-none {
   pointer-events: none;
 }

 .minw-65 {
   min-width: 65px;
 }


 // member listing

 .user_img {
   width: 40px;
   height: 40px;
   min-width: 40px;
   min-height: 40px;
   border-radius: 100%;
   overflow: hidden;
   border: 1px solid var(--grey3);

   img {
     width: 40px;
     height: 40px;
     object-fit: cover;
   }
 }
//   img {
//       width: 40px;
//       height: 40px;
//       object-fit: cover;
//   }
// }
.serach-icon{
  left:25px;
  top: 14px;
  width:18px;
}
// .user-list ul{
//   max-height: 340px;
//   overflow: auto;
// }
.user-list ul li:hover {
  background: #fbfbfb;
}
.user-list ul li.active {
  background: #EAF4FC;
  border-left: 4px solid #E36203;
}
.user-list ul::-webkit-scrollbar {
  width: 7px;
}

 .serach-icon {
   left: 25px;
   top: 14px;
   width: 18px;
 }

 .user-list ul {
   max-height: 340px;
   overflow: auto;
 }
 app-user-card ul {
  max-height: 100% !important;
  overflow: visible !important;
}
 .user-list ul li:hover {
   background: #fbfbfb;
 }

 .user-list ul li.active {
   background: #EAF4FC;
   border-left: 1px solid #E36203;
 }

 .user-list ul::-webkit-scrollbar {
   width: 7px;
 }

 /* Track */
 .user-list ul::-webkit-scrollbar-track {
   background: #cfd3d9;
 }

 /* Handle */
 .user-list ul::-webkit-scrollbar-thumb {
   background: #3b729f;
   border-radius: 10px
 }

 /* Handle on hover */
 .user-list ul::-webkit-scrollbar-thumb:hover {
   background: #386d99;
 }

 // member listing end


 app-toolbox-detail app-landing-page-card-ui .card-banner img {
   height: auto !important;
 }

 .form-check label,
 .form-check input {
   cursor: pointer;
 }
 .count-label,.btn,app-tabs-ui ul.nav.nav-pills a {
  transition: all 0.5s ease;
}
.comment-post-option .badge{border: 1px solid var(--primary);}
 @media(max-width:414px){
  section.emoji-mart {
    width: 100% !important;
}
 }


.global-scroll::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.global-scroll::-webkit-scrollbar-track {
  background: #cfd3d9; 
}
 
/* Handle */
.global-scroll::-webkit-scrollbar-thumb {
  background: #3b729f; 
  border-radius:10px
}

/* Handle on hover */
.global-scroll::-webkit-scrollbar-thumb:hover {
  background: #386d99; 
}

// member listing end


.z-index-1{z-index: 1;}
.sorting-drop button:focus, .sorting-drop button.show, .sorting-drop .btn-outline-secondary.show{background: #ffffff; background-color: #ffffff; }
.outer-report{cursor: pointer;}
.outer-report img{
  width: 13px;
}
.outer-report strong:before {
  content: "";
  height: 19px;
  background: white;
  position: absolute;
  top: -2px;
  bottom: 0;
  width: 19px;
  border-radius: 100%;
  left: -4px;
}

.create-icon{max-height: 25px;}
.card .new-img img, .card-banner img{background: var(--bs-white);}
 

// coach marks

@import '../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';
 
 
ngx-guided-tour .tour-step.tour-bottom .tour-arrow::before, 
ngx-guided-tour .tour-step.tour-bottom-right .tour-arrow::before,
 ngx-guided-tour .tour-step.tour-bottom-left .tour-arrow::before{
  border-bottom: 1rem solid var(--primary);
 }
 ngx-guided-tour .tour-step.tour-left .tour-arrow::before,
 ngx-guided-tour .tour-step.tour-top .tour-arrow::before{
  border-left: 1rem solid var(--primary); display: none !important;
 }
 ngx-guided-tour .tour-step .tour-block {
  position: relative;
  color: #ffffff;
  background-color: var(--primary);
  box-shadow: 0 0.4rem 0.6rem rgba(76, 76, 76, 0.26);
  border-radius: 100%;
  min-height: 300px;
  min-width: 300px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  transition: transform 30s linear;
  -webkit-animation-name: stretch;
  animation-name: stretch;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

ngx-guided-tour .tour-step h3.tour-title {
  font-size: 1.7rem !important;
  padding: 0 !important;
}

ngx-guided-tour .tour-step .tour-content { 
  padding-bottom: 0px;
  font-size: 0.85rem !important;
  color: #c4e5ff;
  text-align: left;
  padding-left: 20px;
  padding-right: 0px;
  position: relative;
  min-height: 10px !important;
  margin-bottom: 25px;
  padding-bottom: 0 !important;
}
ngx-guided-tour .tour-step .tour-content:before {
  content: '';
  height: 100%;
  width: 2px;
  background: #8db6d7;
  position: absolute;
  left: 7px;
}
.tour-buttons button.skip-button {
  font-weight: 500 !important;
  color: white !important; 
}
ngx-guided-tour .tour-step .tour-buttons .next-button {
  padding: 7px 30px !important;
  background: var(--dark-blue);
  border-radius: 40px !important;
  transition: all 0.5s ease;
  border: 1px solid var(--dark-blue) !important;
}
ngx-guided-tour .tour-step .tour-buttons .next-button:hover { 
  background: #0362af; 
}
.tour-buttons {
  display: flex;
  align-items: center;
}
button.back-button.link-button {
  position: absolute !important;
  bottom: 0px;
  float: none;
  left: 0;
  right: 0;
  font-weight: 400 !important;
  color: white !important;
  font-size: 0.82rem !important;
  text-decoration: underline;
  width: 60px;
  margin: 0 auto;
}
 
  @-webkit-keyframes stretch {
    0% {
        box-shadow: 2px 2px 0 25px var(--primary);
        -webkit-box-shadow: 2px 2px 0 25px var(--primary);
        -moz-box-shadow: 2px 2px 0 25px var(--primary);
        -ms-box-shadow: 2px 2px 0 25px var(--primary);
    }

    50% {
        box-shadow: 2px 2px 0 10px var(--primary);
        -webkit-box-shadow: 2px 2px 0 10px var(--primary);
        -moz-box-shadow: 2px 2px 0 10px var(--primary);
        -ms-box-shadow: 2px 2px 0 10px var(--primary)
    }

    to {
        box-shadow: 2px 2px 0 25px var(--primary);
        -webkit-box-shadow: 2px 2px 0 25px var(--primary);
        -moz-box-shadow: 2px 2px 0 25px var(--primary);
        -ms-box-shadow: 2px 2px 0 25px var(--primary);
    }
}

@keyframes stretch {
    0% {
        box-shadow: 2px 2px 0 25px var(--primary);
        -webkit-box-shadow: 2px 2px 0 25px var(--primary);
        -moz-box-shadow: 2px 2px 0 25px var(--primary);
        -ms-box-shadow: 2px 2px 0 25px var(--primary);
    }

    50% {
        box-shadow: 2px 2px 0 10px var(--primary);
        -webkit-box-shadow: 2px 2px 0 10px var(--primary);
        -moz-box-shadow: 2px 2px 0 10px var(--primary);
        -ms-box-shadow: 2px 2px 0 10px var(--primary);
    }

    to {
        box-shadow: 2px 2px 0 25px var(--primary);
        -webkit-box-shadow: 2px 2px 0 25px var(--primary);
        -moz-box-shadow: 2px 2px 0 25px var(--primary);
        -ms-box-shadow: 2px 2px 0 25px var(--primary);
    }
}




ngx-guided-tour .guided-tour-spotlight-overlay { 
  border-radius: 6px;
}

@media(max-width:1199px){ 
  ngx-guided-tour .tour-step {
     
    left: 240px !important;
    top: 90px !important;}
    ngx-guided-tour .tour-step.tour-bottom .tour-arrow:before{display: none !important;}
    ngx-guided-tour .tour-step.tour-top .tour-arrow:before{display: none !important;}
}


@media (max-width: 767px){
ngx-guided-tour .tour-step {
    left: 100px !important;
    top: 90px !important;
}

 
//  coach marks end 
ngx-guided-tour .tour-step .tour-block {
  padding: 15px 25px 10px 5px;
}
ngx-guided-tour .tour-step h3.tour-title {
  font-size: 1.3rem !important; 
}
ngx-guided-tour .tour-step .tour-content {
  font-size: 0.75rem !important;
}
ngx-guided-tour .tour-step .tour-buttons .next-button {
  padding: 7px 18px !important;
}
}
 @media(max-width:360px){
  ngx-guided-tour .tour-step {
    left: 30px !important;
    top: 240px !important;
}
.fixed-tabs ul.nav.nav-pills li a {
  padding: 5px 3px;
}
.tooltip-body  {
  width: 120px;
}
 }

 @media(max-width:1199px){
  ngx-guided-tour .tour-step.tour-top {
    left: 30px !important;
    top: 450px !important;
  }
}
//  coach marks end 




app-add-campaign ngx-slick-carousel button.slick-next.slick-arrow:before ,
app-add-campaign ngx-slick-carousel button.slick-prev.slick-arrow:before
{ 
  background-size: 20px; 
}
app-add-campaign ngx-slick-carousel button.slick-next.slick-arrow:before,

app-add-campaign ngx-slick-carousel button.slick-prev.slick-arrow:before{
  width: 22px;
    height: 22px;
    display: block;
}

app-add-campaign ngx-slick-carousel .slick-prev, 
app-add-campaign ngx-slick-carousel  .slick-next {
  height: 25px;
  width: 25px;
}

app-add-campaign .wrap.slider-view .slick-list {
  border-radius: 0px;
}


app-add-projects ngx-slick-carousel button.slick-next.slick-arrow:before ,
app-add-projects ngx-slick-carousel button.slick-prev.slick-arrow:before
{ 
  background-size: 20px; 
}
app-add-projects ngx-slick-carousel button.slick-next.slick-arrow:before,

app-add-projects ngx-slick-carousel button.slick-prev.slick-arrow:before{
  width: 22px;
    height: 22px;
    display: block;
}

app-add-projects ngx-slick-carousel .slick-prev, 
app-add-projects ngx-slick-carousel  .slick-next {
  height: 25px;
  width: 25px;
}

app-add-projects .wrap.slider-view .slick-list {
  border-radius: 0px;
}


app-add-articles ngx-slick-carousel button.slick-next.slick-arrow:before ,
app-add-articles ngx-slick-carousel button.slick-prev.slick-arrow:before
{ 
  background-size: 20px; 
}
app-add-articles ngx-slick-carousel button.slick-next.slick-arrow:before,

app-add-articles ngx-slick-carousel button.slick-prev.slick-arrow:before{
  width: 22px;
    height: 22px;
    display: block;
}

app-add-articles ngx-slick-carousel .slick-prev, 
app-add-articles ngx-slick-carousel  .slick-next {
  height: 25px;
  width: 25px;
}

app-add-articles .wrap.slider-view .slick-list {
  border-radius: 0px;
}

.text-shadow{
  text-shadow: 0 0 10px #0000002e;
}

.minh-100-custom-1 {
  min-height: 100vh;
 }

 .thumbnail-slide{
  width: 60px;
  height: fit-content;
}

 .thumbnail-active{
  border: 1px solid #0074ca;
}
 .radius-10{
  border-radius: 10px;
}

app-image-slider-modal button.slick-prev.slick-arrow:before {
  content: '';
  background: url(/assets/images/back.png) no-repeat;
  background-size: 40px;
  width: 42px;
  height: 42px;
  display: block;
}

app-image-slider-modal button.slick-next.slick-arrow:before {
  content: '';
  background: url(/assets/images/next.png) no-repeat;
  background-size: 40px;
  width: 42px;
  height: 42px;
  display: block;
}

.modal-dialog.modal-xl .modal-content{
  border-radius: 17px !important;
}

ngb-popover-window{
  inset: auto auto 0px -33px !important;
}

ngb-popover-window  .popover-arrow {
  left: 33px !important;
}

.share-icon {
  cursor: pointer;
  img {
    height: auto;
    width: 26px;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 5px 20px 0 rgba(0, 0, 0, 0.05);
    padding: 0px 4px;
    // background: white;
    // border-radius: 50%;
  }
}

.link-wrap-text{
  word-break: break-all;
}