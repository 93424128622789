@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
    font-family: 'veneer_cleanreg';
    src: url('../fonts/fontsfree-net-veneer-clean-reg-regular-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-veneer-clean-reg-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Roboto+Condensed:wght@700&display=swap');
@import "bootstrap-icons/font/bootstrap-icons.css";

:root {
    --primary: #0072CE;
    --body-text: #1A1616;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-lightyellow:#FDF6EE;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #BDBDBD;
    --dark-blue: #064e89;
    --light-blue: #e5f3ff;
    --grey: #828282;
    --grey2:#4F4F4F;
    --grey3:#F5F5F5;
}


body {
    margin: 0;
    padding: 0;
    border: 0;
    color: var(--body-text);
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
}
*{outline: none;}
a {
    color: var(--primary);
    text-decoration: none;
    cursor: pointer;
    transition: all 0.5s ease;
}

a:hover {
    text-decoration: underline;
}

    :not(.angular-editor-textarea) > ul {
    margin: 0;
    padding: 0;
}


.h1,h1,
.h2,h2
,h3,.h3,
h4,.h4,
h5,.h5
,h6,.h6{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;
}
h1 {
    font-size: 2.2rem;
}

h2 {
    font-size: 2.0rem;
}

h3 {
    font-size: 1.8rem;
}

h4 {
    font-size: 1.6rem;
}

h5 {
    font-size: 1.4rem;
}

p {
    font-size: 1rem;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}
.right-0{right: 0px;}
.left-0{left: 0px;}
.top-n14 {top: -14px;}
.top-10 {top: 10px;}
.top-n10 {top: -10px;}
.left-n10{left: -10px;}
.top-plus-2{top:2px}
.top-plus-7{top:7px}
.top-plus-1{top:1px}
.right-plus-15 {right: 15px;}
.bottom-plus-15 {bottom: 15px;}
.right-plus-25 {right: 25px;}
.bottom-plus-25 {bottom: 25px;}
// font-sizes
.font-veneerbold {
    font-family: 'veneer_cleanreg';
}
// .font-opensans{
//     font-family: 'Open Sans', sans-serif !important;
// }
  .font-inter{
     font-family: 'Inter', sans-serif !important;
  }
  .font-roboto{
    font-family: 'Roboto Condensed', sans-serif;
  }
  .fs-11 {
    font-size: 0.70rem;
}
  .fs-12 {
    font-size: 0.75rem !important;
}
.fs-13 {
    font-size: 0.8rem !important;
}
.fs-14 {
    font-size: 0.85rem;
}

.fs-15 {
    font-size: 0.94rem;
}

.fs-18 {
    font-size: 1.15rem !important;
}
.fs-20 {
    font-size: 1.25rem !important;
}
.fs-22 {
    font-size: 1.4rem !important;
}

.fs-60 {
    font-size: 3.7rem;
}

.text-grey {
    color: var(--bs-gray);
}
.text-grey-custom {
    color: var(--grey);
}
.text-grey-2 {
    color: var(--grey2);
}
.opacity-1 {
    opacity: 0.1;
}
.opacity-1 {
    opacity: 0.1;
}
.bg-grey{background-color: var(--grey3);}
.bg-lightyellow{background-color: var(--bs-lightyellow);}
a.text-dark:hover{color:var(--primary) !important;}

.text-primary{color:var(--primary) !important;}

.btn {
    font-family: "Inter", sans-serif;
    min-width: 120px;
    padding: 8px 18px;
    font-size: 0.9rem;
    font-weight: 600;
}

.btn-primary {
    background-color: var(--primary);
}

.btn-primary:hover,
.btn-primary:active {
    background-color: var(--dark-blue) !important;
}

.btn-outline-primary {
    background-color: transparent !important;
    color: var(--primary);
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
    color: var(--bs-white);
    background-color: var(--dark-blue) !important;
    border: 1px solid var(--dark-blue) !important;
}

.btn-outline-default {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
}

.btn-outline-default:hover,
.btn-outline-default:active {
    background-color: var(--dark-blue) !important;
    border: 1px solid var(--dark-blue) !important;
    color: #ffffff;
}

.btn-outline-secondary {
    background-color: transparent;
    border: 1px solid var(--body-text);
    color: var(--body-text);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active {
    background-color: var(--dark-blue) !important;
    border: 1px solid var(--dark-blue) !important;
    color: var(--bs-white);
}
.btn-close:focus{box-shadow: none !important;}


.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #329504;
    --bs-btn-border-color: #329504;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #2d8305;
    --bs-btn-hover-border-color: #2d8305;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #2d8305;
    --bs-btn-active-border-color: #2d8305;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #2d8305;
    --bs-btn-disabled-border-color: #2d8305;
  }
   

.btn-sm{font-size: 0.8rem;}
.mw-240 {
    min-width: 240px !important;
}

@media(min-width:1200px){
.mxw-1130 {
    max-width: 1130px;
}
}
.minw-auto{min-width:auto;}
.bg-black {
    background: var(--body-text);
}

.z-index-1 {
    z-index: 1;
}
.z-index-9 {
    z-index: 9;
}
.z-index-10 {
    z-index: 10;
}
.z-index-99999-higher {
    z-index: 99999;
}
.radius-15{border-radius: 15px;} 
// input checkbox
.cursor-pointer {
    cursor: pointer;
}
.form-control,.form-select{font-size: 0.9rem;}
.form-control:focus,
.form-check-input:focus {
    box-shadow: none;
}

.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}

.outline-red {
    border-color: red;
}

.danger {
    font-size: 14px;
    color: red;
}

.shadow-lg-custom {
    // box-shadow: 0 1rem 3rem rgba(0,0,0,.105)!important;
    box-shadow: 2px 2px 32px rgba(44, 41, 41, 0.09);
}

.border-grey-custom {
    border: 1px solid #e7e3e3;
}

.border-danger{border: 1px solid var(--bs-red);}





.global-scrollbar::-webkit-scrollbar ,.card-body.contacts_body::-webkit-scrollbar {
    width: 7px;
  }

/* Track */
 .global-scrollbar::-webkit-scrollbar-track, .card-body.contacts_body::-webkit-scrollbar-track {
    background: #cfd3d9; 
  }
   
  /* Handle */
 .global-scrollbar::-webkit-scrollbar-thumb,  .card-body.contacts_body::-webkit-scrollbar-thumb {
    background: #3b729f; 
    border-radius:10px
  }
  
  /* Handle on hover */
 .global-scrollbar::-webkit-scrollbar-thumb:hover,
 .card-body.contacts_body::-webkit-scrollbar-thumb:hover {
    background: #386d99; 
  }

  .card-body.contacts_body{max-height: 60vh; overflow: auto;}

// member listing end